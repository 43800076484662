import React from 'react';
import Card from 'react-bootstrap/Card';
import '../styles/TreeSupport.css';
import background from '../images/trees4.jpg';
import treeSupport from '../images/supportSystems1.jpg';
import Page from '../components/Page';
import treeSupport2 from '../images/supportSystems2.jpg';
import cabling from '../images/supportSystems3.jpg';

const TreeSupport = () => {
  return (
    <Page
      title={'Dendro - Tree Support Systems'}
      description={
        'Our tree support systems provide the perfect balance of strength and flexibility to keep your trees standing tall and healthy.'
      }
      pageLink={'/supportsystems'}
    >
      <div>
        <div className='plantHealthContainer'>
          <div
            className='backgroundImage'
            // background image
            style={{
              backgroundImage: `url(${background})`,
              // backgroundPosition: 'center',
              // backgroundSize: 'cover',
              // backgroundRepeat: 'no-repeat',
              // backgroundAttachment: 'fixed',
              // height: 'auto',
              // width: '100%',
              // zIndex: 1,
              opacity: '0.4',
            }}
          ></div>

          <div className='titleBoxPlantHealth'></div>
          <h1 className='titlePlantHealth'>TREE SUPPORT SYSTEMS</h1>
          <Card className='textPlantHealth'>
            <Card.Img
              variant='top'
              src={treeSupport}
              alt='large tree with large branch detached'
              style={{ opacity: '0.9' }}
            />
            <h2
              style={{
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'space-around',
              }}
              className='citeText'
            >
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5043015'
              >
                Joseph OBrien, USDA Forest Service, Bugwood.org
              </a>
            </h2>

            <Card.Body>
              <div>
                <p>
                  Are you tired of worrying about the safety and health of your
                  trees? Do you live in an area prone to lightning strikes and
                  storms? Look no further than our tree support systems and
                  lightning protection solutions.
                </p>
                <p>
                  Tree support systems are vital tools in the arboriculture
                  toolkit, designed to provide structural reinforcement and
                  stability to trees. These systems aim to safeguard against the
                  risk of failure in weak, damaged, or vulnerable areas of a
                  tree. Commonly used methods such as cabling, bracing, and
                  staking are employed to support compromised branches or guide
                  the growth of young trees. Cables and braces are strategically
                  installed to distribute stress more evenly among a tree's
                  structure, preventing potential damage or breakage, while
                  stakes assist in establishing a strong, upright growth
                  pattern. These systems not only enhance the structural
                  integrity of trees but also contribute to the overall safety
                  and health of the surrounding landscape.
                </p>
                <div
                  style={{
                    float: 'right',
                    flex: '1',
                    width: '200px',
                    marginBottom: '15px',
                    maxWidth: '100%',
                    position: 'relative',
                  }}
                  className='imageTextEmbed'
                >
                  <img alt='' src={treeSupport2} style={{ width: '100%' }} />

                  <h3
                    style={{
                      position: 'absolute',
                      // zIndex: 2,
                      bottom: '5px',
                      right: '3px',
                    }}
                  >
                    <a
                      arial-label='link to source of image'
                      style={{
                        color: 'rgb(218, 204, 204)',
                        textDecoration: 'none',
                        fontSize: '0.4rem',
                      }}
                      href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5048029'
                    >
                      Joseph OBrien, USDA Forest Service, Bugwood.org
                    </a>
                  </h3>
                </div>

                <p>
                  In addition to tree support systems, we also offer lightning
                  protection solutions to keep your property and trees safe from
                  the destructive power of lightning strikes. Our lightning
                  protection systems are designed to intercept and safely
                  disperse lightning strikes, preventing damage to your trees
                  and other property. By installing lightning rods and
                  conductors, you can rest easy knowing that your trees and
                  property are protected from this unpredictable natural
                  phenomenon.
                </p>
                <div
                  style={{
                    float: 'left',
                    flex: '1',
                    width: '200px',
                    marginBottom: '15px',
                    maxWidth: '100%',
                    position: 'relative',
                  }}
                  className='imageTextEmbed'
                >
                  <img alt='' src={cabling} style={{ width: '100%' }} />

                  <h3
                    style={{
                      position: 'absolute',
                      // zIndex: 2,
                      bottom: '5px',
                      right: '3px',
                    }}
                  >
                    <a
                      arial-label='link to source of image'
                      style={{
                        color: 'rgb(218, 204, 204)',
                        textDecoration: 'none',
                        fontSize: '0.4rem',
                      }}
                      href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5048029'
                    >
                      International Society of Arboriculture, Bugwood.org
                    </a>
                  </h3>
                </div>
                <p>
                  Don't let worry about tree damage or lightning strikes ruin
                  your peace of mind. Contact us today to learn more about our
                  tree support systems and lightning protection solutions and
                  take the first step towards protecting your trees and
                  property.
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default TreeSupport;
