import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../styles/Navigation.css';
import logo from '../images/Logo White PNG - 90x53.png';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Navigation() {
  const [expanded, setExpanded] = useState(false);

  // Make sure browser scrolls to top when navigating to new page
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Navbar
      expanded={expanded}
      expand='lg'
      className='navbar navbar-dark bg-dark fixed-top'
    >
      <Container>
        <Navbar.Brand className='dendroTitle'>
          <img
            alt='Dendro logo'
            src={logo}
            width='60'
            height='35'
            className='d-inline-block align-top logoNavBar'
          />
          <NavLink
            to='/'
            style={{ textDecoration: 'none', color: 'rgb(216, 210, 210)' }}
          >
            Dendro Tree Care
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        />
        <Navbar.Collapse id='basic-navbar-nav'>
          <NavLink
            to='/'
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? 'bold' : '',
                color: isPending ? 'red' : 'white',
              };
            }}
            onClick={() => setExpanded(false)}
            className='dendroNavbar'
          >
            Home
          </NavLink>
          <NavDropdown
            title='Services'
            id='basic-nav-dropdown'
            className='dendroNavbar dropdownServices'
          >
            <NavDropdown.Item>
              <NavLink
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                  };
                }}
                onClick={() => setExpanded(false)}
                to='/pruning'
                className='dendroNavbar'
              >
                Pruning
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink
                onClick={() => setExpanded(false)}
                to='/consulting'
                className='dendroNavbar '
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                  };
                }}
              >
                Consulting Services
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                  };
                }}
                onClick={() => setExpanded(false)}
                to='/planthealth'
                className='dendroNavbar '
              >
                Plant Health Services
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                  };
                }}
                onClick={() => setExpanded(false)}
                to='/supportsystems'
                className='dendroNavbar '
              >
                Tree Support Systems
              </NavLink>
            </NavDropdown.Item>
          </NavDropdown>
          <NavLink
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? 'bold' : '',
                color: isPending ? 'red' : 'white',
              };
            }}
            to='/learningresources'
            className='dendroNavbar'
            onClick={() => setExpanded(false)}
          >
            Learning Resources
          </NavLink>
          <NavLink
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? 'bold' : '',
                color: isPending ? 'red' : 'white',
              };
            }}
            to='/about'
            className='dendroNavbar'
            onClick={() => setExpanded(false)}
          >
            About Us
          </NavLink>
          <NavLink
            style={({ isActive, isPending }) => {
              return {
                fontWeight: isActive ? 'bold' : '',
                color: isPending ? 'red' : 'white',
              };
            }}
            to='/contact'
            className='dendroNavbar'
            onClick={() => setExpanded(false)}
          >
            Contact Us
          </NavLink>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
