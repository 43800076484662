import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import '../styles/ImageCarousel.css';
import { Link } from 'react-router-dom';

const ImageCarousel = () => {
  const carouselImages = [
    {
      image: require('../images/treeclimb.jpg'),
      description: 'Benefits Of Pruning',
      alt: 'Tree Climber on tree pruning a branch',
      to: '/pruning',
    },
    {
      image: require('../images/monocle.jpeg'),
      description: 'Plant Health Care',
      alt: 'Looking at a leaf through a monocle',
      to: '/plantHealth',
    },
    {
      image: require('../images/gingoCanopy.jpg'),
      description: 'The Impact Of Climate Change On Trees',
      alt: 'Canopy of a Ginkgo tree with blue sky behind it',
      to: 'learningResources/GlobalWarming',
    },
  ];
  return (
    <div
      style={{
        width: '60% ',
        backgroundColor: 'rgba(255, 255, 255, 0)',
      }}
      className='rounded-5 carouselCard'
    >
      <div>
        <Carousel
          fade
          style={{
            width: '100%',
          }}
          className='shadow-lg rounded-5 carouselMargin'
        >
          {carouselImages.map((slide, i) => {
            return (
              <Carousel.Item key={slide.image} interval={3000}>
                <Link
                  to={slide.to}
                  aria-label={`link behind the image to be directed to the ${slide.description} page`}
                >
                  <img
                    className='d-block w-100 rounded-5 carouselImage'
                    src={slide.image}
                    alt={slide.alt}
                    style={{ opacity: '0.7' }}
                  />
                </Link>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default ImageCarousel;
