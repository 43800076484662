import React from 'react';
import background from '../images/trees1.jpg';
import '../styles/LearningResources.css';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Page from '../components/Page';

const LearningResources = () => {
  const blogData = [
    {
      name: 'globalwarming',
      image: require('../images/drySoil.jpg'),
      title: 'The impact of climate change on our urban trees',
      alt: 'Image of Dry soil',
      datePosted: '05/23/2022',
    },
    // {
    //   name: 'boxwoodcareguide',
    //   image: require('../images/boxwoodLeafminer4.jpg'),
    //   title: 'Boxwood Care Guide',
    //   alt: 'Boxwood plant',
    //   datePosted: '04/05/2023',
    // },
  ];

  return (
    <Page
      title={'Dendro - Learning Resources'}
      description={'Learn more about trees through our Learning Resources.'}
      pageLink={'/learningresources'}
    >
      <div className='externalDiv'>
        <div className='BlogContainer'>
          <div
            className='backgroundImage'
            style={{
              backgroundImage: `url(${background})`,
              opacity: '0.4',
            }}
          ></div>

          <h1 className='titleBlog'>LEARNING RESOURCES</h1>
          {blogData.map((blog, i) => (
            <div className=' mb-3'>
              <Card
                key={blog.name}
                className='textBlog'
                style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
              >
                <div className='row g-0'>
                  <div className='col-md-4'>
                    <Card.Img
                      src={blog.image}
                      alt={blog.alt}
                      className='img-fluid rounded-start '
                    />
                  </div>
                  <div
                    className='col-md-8'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Card.Body className='card-body'>
                      <Card.Title>
                        <Link
                          aria-label={`link to read about ${blog.name}`}
                          to={`/learningresources/${blog.name}`}
                          className='linkToArticle'
                          style={{ color: 'black' }}
                        >
                          {blog.title}
                        </Link>
                      </Card.Title>
                      <Card.Text
                        className='text-muted'
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        Posted on {blog.datePosted}
                      </Card.Text>
                    </Card.Body>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default LearningResources;
