import React from 'react';
import background from '../images/forestHigh.webp';
import '../styles/Landing.css';
import GeneralInfoCard from '../components/GeneralInfoCard';
import ServicesCards from '../components/ServicesCards';
import ContactForm from '../components/ContactForm';
import WhyUs from '../components/WhyUs';
import Page from '../components/Page';

const Landing = () => {
  return (
    <Page
      title={'Dendro Tree Care'}
      description='Dendro Tree Care is a locally owned and operated full-service tree care company located in Nashville, Tennessee. Founded by an ISA Board Certified Master Arborist, Dendro Tree Care is committed to providing the highest level of safe and scientific tree care to its clients.'
      pageLink={'/'}
    >
      <div className='landingContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.8',
          }}
        ></div>
        <div className='content'>
          <GeneralInfoCard />
          <ServicesCards />
          <WhyUs />
          <ContactForm />
        </div>
      </div>
    </Page>
  );
};

export default Landing;
