import React from 'react';
import '../styles/WhyUs.css';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import checkmark from '../images/checkmark.png';
import { Slide } from 'react-awesome-reveal';

const WhyUs = () => {
  return (
    <div>
      <Card className='cardWhyUs'>
        <h2 className='whyTitle'> Why Choose Us? </h2>
        <ListGroup variant='flush'>
          <Slide triggerOnce>
            <p className='listItemsWhy' style={{ marginTop: '20px' }}>
              <img
                alt='checkmark button'
                src={checkmark}
                className='checkmark'
              />
              ISA Board Certified Master Arborist
            </p>
          </Slide>
          <Slide triggerOnce>
            <p className='listItemsWhy'>
              <img
                alt='checkmark button'
                src={checkmark}
                className='checkmark'
              />
              We Stand By Our Values
            </p>
          </Slide>
          <Slide triggerOnce>
            <p className='listItemsWhy'>
              <img
                alt='checkmark button'
                src={checkmark}
                className='checkmark'
              />
              Low-Impact & Environmentally Conscious Approach To Tree Care
            </p>
          </Slide>
          <Slide triggerOnce>
            <p className='listItemsWhy'>
              <img
                alt='checkmark button'
                src={checkmark}
                className='checkmark'
              />
              Customer Service That You Can Count On
            </p>
          </Slide>
          <Slide triggerOnce>
            <p className='listItemsWhy'>
              <img
                alt='checkmark button'
                src={checkmark}
                className='checkmark'
              />
              Licensed & Insured
            </p>
          </Slide>
        </ListGroup>
      </Card>
    </div>
  );
};

export default WhyUs;
