import React from 'react';
import Card from 'react-bootstrap/Card';
import '../styles/PestManagement.css';
import background from '../images/trees4.jpg';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Page from '../components/Page';

const PestManagement = () => {
  const pestData = [
    {
      image: require('../images/boxwoodLeafminer4.jpg'),
      name: 'Boxwood Leafminer',
      alt: 'Boxwood Leafminer damage shown on crusty and yellow leaves',
      title: 'BOXWOOD LEAFMINER',
      urlName: 'boxwoodleafminer',
    },
    {
      image: require('../images/rootrot1.jpg'),
      name: 'Phytopthora Root Rot',
      title: 'PHYTOPTHORA ROOT ROT',
      alt: 'Phytopthora Root Rot Damage',
      urlName: 'rootrot',
    },
    {
      image: require('../images/dogwoodAnthracnose1.jpg'),
      name: 'Dogwood Anthracnose',
      title: 'DOGWOOD ANTHRACNOSE',
      alt: 'Dogwood Anthracnose Damage shown on dogwood leaves',
      urlName: 'dogwoodanthracnose',
      credit: 'John Hartman, University of Kentucky, Bugwood.org',
      creditLink:
        'https://www.forestryimages.org/browse/detail.cfm?imgnum=5430479',
    },
    {
      image: require('../images/spiderMites1.jpg'),
      name: 'Spider Mites',
      title: 'SPIDER MITES',
      alt: 'Spider Mites damage',
      urlName: 'spidermites',
      credit:
        'John A. Weidhass, Virginia Polytechnic Institute and State University, Bugwood.org',
      creditLink:
        'https://www.forestryimages.org/browse/detail.cfm?imgnum=1626017',
    },
    {
      image: require('../images/EABdamage.jpg'),
      name: 'Emerald Ash Borer',
      title: 'EMERALD ASH BORER',
      alt: 'Emerald Ash Borer damage on a tree trunk',
      urlName: 'eab',
    },
    {
      image: require('../images/aphids1.jpg'),
      name: 'Aphids',
      title: 'APHIDS',
      alt: 'Aphids on a branch and leaves - visible damage on blackened leaves',
      urlName: 'aphids',
    },
    {
      image: require('../images/bagworms2.jpg'),
      name: 'Bagworms',
      title: 'BAGWORMS',
      alt: 'Bagworms hanging off a japanese maple',
      urlName: 'bagworms',
      // credit: 'Lacy L. Hyche, Auburn University, Bugwood.org',
      // creditLink:
      //   'https://www.forestryimages.org/browse/detail.cfm?imgnum=1431050',
    },
    {
      image: require('../images/crapeMyrtle.jpg'),
      name: 'Crape Myrtle Bark Scale',
      title: 'CRAPE MYRTLE BARK SCALE',
      alt: 'Crape Myrtle Bark Scale damage, visible blackened leaves',
      urlName: 'crapemyrtlebarkscale',
      // credit: 'Jim Robbins, Univ. of Ark. CES, Bugwood.org',
      // creditLink:
      //   'https://www.forestryimages.org/browse/detail.cfm?imgnum=5521505',
    },
  ];
  return (
    <Page
      title={'Dendro - Common Pests'}
      description={'Learn about the different pests located in our area.'}
      pageLink={'/pestmanagement'}
    >
      <div className='plantHealthContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>
        <div
          style={{
            width: '60%',
            border: 'none',
          }}
          className='rounded-5 pestsCard'
        >
          <h2 className='pestsTitle' id='pest'>
            Common Pests In Our Landscapes
          </h2>

          <Row xs={1} md={2}>
            {pestData.map((card, i) => (
              <Col xs={12} md={6} key={card.name} className='imageCards'>
                <Card
                  style={{
                    border: 'none',
                    marginBottom: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  }}
                  className='cardBlock'
                >
                  <Card.Img
                    variant='top'
                    style={{
                      width: '100%',
                      height: '60vh',
                      objectFit: 'cover',
                      opacity: '0.9',
                      backgroundSize: 'cover',
                    }}
                    src={card.image}
                    alt={card.alt}
                  />

                  <div
                    style={{
                      position: 'absolute',
                      bottom: '5px',
                      right: '3px',
                    }}
                  >
                    <h3>
                      <a
                        className='citeText'
                        arial-label='link to source of image'
                        style={{
                          color: 'rgb(255, 255, 255)',
                          textDecoration: 'none',
                          fontSize: '8px',
                        }}
                        href={card.creditLink}
                      >
                        {card.credit}
                      </a>
                    </h3>
                  </div>
                </Card>
                <Button
                  className='pestsButton'
                  aria-label={`button to read more about ${card.title}`}
                >
                  <Link
                    aria-label={`link to read more about ${card.title}`}
                    to={`/commonpests/${card.urlName}`}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    {card.name}
                  </Link>
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Page>
  );
};

export default PestManagement;
