import { Helmet } from 'react-helmet-async';

function Page({ title, description, pageLink, children }) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`http://dendrotreecare.com${pageLink}`} />
        <meta name='description' content={description} />
      </Helmet>
      {children}
    </>
  );
}

export default Page;
