import React from 'react';
import phoneIcon from '../images/phoneFloat.png';
import emailIcon from '../images/emailFloat.png';
import textIcon from '../images/messageFloat.png';
import { Button } from 'react-bootstrap';
import '../styles/PhoneFloat.css';

const PhoneFloat = () => {
  return (
    <div className='phoneFloatContainer'>
      <Button
        aria-label='phone button to call Dendro Tree Care'
        className='phoneBtnDiv'
        style={{ border: 'none', borderRadius: '200px' }}
      >
        <a
          href='tel:9312550875'
          aria-label='phone button to call Dendro Tree Care'
        >
          <img
            alt='phone icon to call us'
            src={phoneIcon}
            className='phoneBtn'
          />
        </a>
      </Button>
      <Button
        aria-label='email button to email Dendro Tree Care'
        className='phoneBtnDiv'
        style={{ border: 'none', borderRadius: '200px' }}
      >
        <a
          href='mailto:info@dendrotreecare.com'
          aria-label='email button to email Dendro Tree Care'
        >
          <img
            alt='email icon to email Dendro Tree Care'
            src={emailIcon}
            className='phoneBtn'
          />
        </a>
      </Button>
      <Button
        aria-label='sms button to text Dendro Tree Care'
        className='phoneBtnDiv'
        style={{ border: 'none', borderRadius: '200px' }}
      >
        <a
          href='sms:6153979021'
          aria-label='sms button to text Dendro Tree Care'
        >
          <img
            alt='text button to send an sms to Dendro Tree Care'
            src={textIcon}
            className='phoneBtn'
          />
        </a>
      </Button>
    </div>
  );
};

export default PhoneFloat;
