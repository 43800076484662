import React from 'react';
import facebookIcon from '../images/facebook.png';
import instagramIcon from '../images/instagram.png';
import emailIcon from '../images/email.png';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <div className='footer-text pt-3'>All rights reserved &copy;</div>
        <ul className='icons'>
          <li>
            <a
              aria-label='Email icon to send an email'
              target='_blank'
              rel='noreferrer'
              href='mailto:info@dendrotreecare.com'
            >
              <img alt='Email icon' src={emailIcon} className='footerIcons' />
            </a>
          </li>
          <li>
            <a
              aria-label="instagram icon redirecting to Dendro Tree Care's instagram page"
              href='https://www.instagram.com/dendrotreecare/'
              target='_blank'
              rel='noreferrer'
            >
              <img
                alt='Instagram icon'
                src={instagramIcon}
                className='footerIcons'
              />
            </a>
          </li>
          <li>
            <a
              aria-label="Facebook icon redirecting to Dendro Tree Care's Facebook page"
              href='https://www.facebook.com/dendrotreecare'
              target='_blank'
              rel='noreferrer'
            >
              <img
                alt='Facebook icon'
                src={facebookIcon}
                className='footerIcons'
              />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
