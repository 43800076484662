import React from 'react';
import background from '../images/leaf1.jpg';
import Card from 'react-bootstrap/Card';
import { useParams } from 'react-router-dom';
import '../styles/CommonPests.css';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import bagworms1 from '../images/bagworms.jpg';
import barkScale1 from '../images/crapeMyrtleBarkScale1.jpg';
import barkScale2 from '../images/crapeMyrtleBarkScale2.jpg';

const CommonPests = () => {
  const { pestpage } = useParams();
  // console.log(pestPage);

  const differentPests = [
    {
      image: require('../images/boxwoodLeafminer6.png'),
      title: 'BOXWOOD LEAFMINER',
      alt: 'Boxwood Leafminer bug shown on the inside of a boxwood leaf',
      urlName: 'boxwoodleafminer',
      credit: 'Jim Baker, North Carolina State University, Bugwood.org',
      creditLink:
        'https://www.forestryimages.org/browse/detail.cfm?imgnum=1543686',
      description: [
        <p>
          Boxwood leafminer, also known as Monarthropalpus buxi, is a tiny fly
          that causes significant damage to boxwood plants. The insect is native
          to Europe and was first detected in the United States in the mid-20th
          century.
        </p>,
        <p>
          The female boxwood leafminer lays its eggs on the undersides of
          boxwood leaves in the spring. When the larvae hatch, they burrow into
          the leaves, creating tunnels as they feed. This feeding activity
          causes the leaves to turn brown and die, which can severely damage the
          appearance and health of the plant.
        </p>,
        <p>
          The boxwood leafminer is most active in the spring and early summer
          when the weather is warm and humid. The adult flies emerge from the
          pupal stage in the soil and feed on the leaves before laying their
          eggs.
        </p>,
        <p>
          There are several signs that a boxwood plant may be infested with
          leafminer. One of the most noticeable signs is the appearance of brown
          blotches on the leaves. The leaves may also curl or twist as a result
          of the larvae feeding inside them. In severe cases, the plant may
          experience stunted growth or dieback.
        </p>,
        <p>
          To prevent and control boxwood leafminer, it's essential to maintain
          healthy boxwood plants. This includes regular fertilization, watering,
          and pruning. Insecticides can also be used to control the population
          of leafminer larvae, although it's important to choose products that
          are safe for use on boxwood plants and to follow the instructions
          carefully.
        </p>,
        <p>
          Overall, the boxwood leafminer is a significant pest that can cause
          serious damage to boxwood plants if left untreated. By being vigilant
          and taking proactive measures to prevent infestations, gardeners and
          landscapers can protect their boxwood plants and keep them looking
          healthy and beautiful.
        </p>,
      ],
    },
    {
      image: require('../images/rootrot3.jpg'),
      title: 'PHYTOPTHORA ROOT ROT',
      alt: 'Phytopthora Root Rot Damage visible on an arborvitae',
      credit: 'John Ruter, University of Georgia, Bugwood.org',
      creditLink:
        'https://www.forestryimages.org/browse/detail.cfm?imgnum=1603623',
      urlName: 'rootrot',
      description: [
        <p>
          Phytophthora Root Rot is a plant disease caused by a soil-borne
          pathogen of the Phytophthora genus, which can affect a wide range of
          plant species. The disease is characterized by the rotting and decay
          of the roots, leading to stunted growth, wilting, and eventual death
          of the plant.
        </p>,
        <p>
          The Phytophthora pathogen attacks the roots of the plant, causing them
          to become soft and mushy. The infected roots lose their ability to
          absorb water and nutrients, which results in the plant becoming weak
          and wilted. The disease can spread rapidly, especially in warm and
          moist conditions, and can lead to extensive damage in crops and
          ornamental plants.
        </p>,
        <p>
          The symptoms of Phytophthora Root Rot can vary depending on the plant
          species affected. In some cases, the leaves may turn yellow or brown,
          and the plant may appear stunted and wilted. In other cases, the roots
          may become dark and discolored, and the plant may develop cankers or
          lesions on the stem.
        </p>,
        <p>
          Preventing Phytophthora Root Rot requires careful management of soil
          moisture, drainage, and sanitation practices. Planting resistant
          varieties of crops and avoiding the use of infected soil or plant
          materials can also be effective in preventing the disease from
          spreading
        </p>,
        <p>
          Treatment options for Phytophthora Root Rot are limited, and once the
          disease has taken hold, it is often difficult to control. Fungicides
          may be effective in preventing the spread of the disease, but they are
          most effective when used as a preventative measure before the disease
          has become established.
        </p>,
        <p>
          Phytophthora Root Rot is a serious plant disease that can cause
          significant damage to crops and ornamental plants. Early detection and
          prevention are key to managing the disease, and proper sanitation
          practices and the use of resistant plant varieties can help reduce the
          risk of infection.
        </p>,
      ],
    },
    {
      image: require('../images/dogwoodAnthracnose2.jpg'),
      title: 'DOGWOOD ANTHRACNOSE',
      alt: 'Dogwood Anthracnose Damage shown on the back of dogwood flowers',
      urlName: 'dogwoodanthracnose',
      description: [
        <p>
          Dogwood anthracnose is a fungal disease that affects dogwood trees,
          specifically the flowering dogwood <i>(Cornus florida)</i> and the
          Pacific dogwood <i>(Cornus nuttallii)</i>. The disease is caused by
          the fungus Discula destructiva, which was first identified in the
          1990s and has since become a significant threat to dogwood populations
          in North America.
        </p>,
        <p>
          Symptoms of dogwood anthracnose include leaf blight, stem cankers, and
          dieback of branches. The disease can cause the leaves to develop
          irregularly shaped, tan spots with purple rims. These spots can merge
          and cause the leaves to turn brown and drop prematurely. The stems can
          also develop cankers, which are sunken, necrotic areas that can girdle
          the stem and cause it to break. The disease can progress to cause
          entire branches to die back.
        </p>,
        <p>
          Dogwood anthracnose is spread through spores that are released from
          infected leaves and twigs. These spores can be carried by wind or rain
          to infect healthy dogwood trees. The disease is most severe in areas
          with high humidity and rainfall.
        </p>,
        <p>
          There are several ways to manage dogwood anthracnose. One approach is
          to plant dogwood varieties that are resistant to the disease, such as
          the Kousa dogwood <i>(Cornus kousa)</i>. Proper care of the tree,
          including regular watering, fertilization, and pruning of dead or
          diseased branches, can also help to prevent the disease from taking
          hold. Fungicide treatments may also be effective in controlling the
          disease, but these should be used in conjunction with other management
          practices.
        </p>,
        <p>
          Dogwood anthracnose is a serious fungal disease that can cause
          significant damage to dogwood trees. It is important for homeowners
          and landscapers to be aware of the symptoms of the disease and take
          steps to prevent its spread. By planting resistant varieties,
          providing proper care, and using appropriate management practices, it
          is possible to protect dogwood trees from this destructive disease.
        </p>,
      ],
    },
    {
      image: require('../images/spiderMites2.jpg'),
      title: 'SPIDER MITES',
      alt: 'Spider Mite bug (two-spotted) close-up image',
      urlName: 'spidermites',
      credit: 'Eugene E. Nelson, Bugwood.org',
      creditLink:
        'https://www.forestryimages.org/browse/detail.cfm?imgnum=5356811',
      description: [
        <p>
          Spider mites are tiny arachnids that are commonly found in gardens,
          greenhouses, and indoor plants. They belong to the family
          Tetranychidae, and there are several species of spider mites,
          including the two-spotted spider mite and the red spider mite.
        </p>,
        <p>
          These pests are very small, typically less than 1/50 of an inch in
          length, and they are usually difficult to see with the naked eye. They
          are usually reddish or greenish in color, depending on the species,
          and they have eight legs. Spider mites can reproduce quickly and in
          large numbers, making them a serious pest for plants.
        </p>,
        <p>
          Spider mites feed on the sap of plants, piercing the leaves and stems
          with their mouthparts and sucking out the sap. This can cause a number
          of problems for plants, including stunted growth, yellowing leaves,
          and reduced yield. In severe cases, spider mite infestations can even
          lead to plant death.
        </p>,
        <p>
          One of the key indicators of spider mites is the presence of fine
          webbing on plants. This webbing is often mistaken for spider webs, but
          it is actually produced by the mites themselves. The webbing provides
          a protective shelter for the mites and their eggs, making it difficult
          to control the infestation.
        </p>,
        <p>
          There are several methods of controlling spider mites. One approach is
          to simply wash the affected plants with a strong stream of water,
          which can dislodge and kill many of the mites. Insecticidal soaps and
          oils can also be effective, as can biological control methods such as
          introducing predatory mites or ladybugs to the affected area.
        </p>,
        <p>
          Preventing spider mite infestations in the first place is also
          important. This can be done by maintaining a healthy growing
          environment for plants, avoiding over-fertilization, and regularly
          inspecting plants for signs of pest activity.
        </p>,
        <p>
          Spider mites are a common pest that can cause serious damage to
          plants. Early detection and prevention are key to controlling
          infestations, and a range of methods are available for treating the
          problem.
        </p>,
      ],
    },
    {
      image: require('../images/emeraldAshBorer.jpg'),
      title: 'EMERALD ASH BORER',
      alt: 'Emerald Ash Borer bug close-up',
      urlName: 'eab',

      description: [
        <p>
          Emerald ash borer (EAB) is a highly destructive invasive beetle that
          attacks and kills ash trees in North America. It is believed to have
          originated from Asia, and was first detected in Michigan in 2002.
          Since then, it has spread to 35 states and 5 Canadian provinces,
          causing extensive damage to millions of ash trees. Emerald Ash Borer
          first entered Davidson County in 2014 and has already devastated a
          large number of our Ash trees.
        </p>,
        <p>
          The adult emerald ash borer is a small, metallic green beetle that is
          about 1/2 inch long. It feeds on the leaves of ash trees during its
          brief adult stage, but it is the larval stage that causes the most
          damage. The larvae feed on the inner bark of ash trees, disrupting the
          tree's ability to transport nutrients and water. This causes the tree
          to decline and eventually die within a few years.
        </p>,
        <p>
          The spread of emerald ash borer is largely due to the transportation
          of infested firewood and other wood products. Once an infestation is
          detected, it is difficult to control and contain. The most effective
          method for managing EAB is through a combination of chemical
          treatments and tree removal. However, chemical treatments can be
          expensive and must be applied regularly to maintain their
          effectiveness.
        </p>,
        <p>
          The economic impact of emerald ash borer is significant, with the cost
          of ash tree removal and replacement estimated to be in the billions of
          dollars. Additionally, ash trees are an important component of many
          ecosystems, providing habitat and food for a variety of wildlife
          species. The loss of ash trees can have a ripple effect throughout the
          ecosystem, impacting other plants and animals that rely on them.
        </p>,
        <p>
          Efforts are underway to control the spread of emerald ash borer and to
          develop more effective management strategies. These include the
          development of biological control methods, such as the introduction of
          natural predators, as well as ongoing research into the beetle's
          behavior and biology. However, the best defense against emerald ash
          borer is prevention. This includes avoiding the transportation of
          firewood and other wood products across state and provincial borders,
          and supporting efforts to detect and control infestations early.
        </p>,
        <p>For more information on Emerald Ash Borer, see the links below:</p>,
        <Link aria-label='link to Nashville.gov for an articule about EAB.'>
          www.nashville.gov/departments/water/stormwater/tree-information/emerald-ash-borer
        </Link>,
        <Link aria-label='link in nashville tree conservation corps to learn more about EAB.'>
          www.nashvilletreeconservationcorps.org/treenews/nashville-eab-update
        </Link>,
      ],
    },
    {
      image: require('../images/aphids2.jpg'),
      title: 'APHIDS',
      alt: 'close-up image of aphids on a leaf',
      urlName: 'aphids',
      credit: 'Whitney Cranshaw, Colorado State University, Bugwood.org',
      creditLink:
        'https://www.forestryimages.org/browse/detail.cfm?imgnum=5367183',
      description: [
        <p>
          Aphids are small, soft-bodied insects that feed on the sap of plants
          and trees. They are usually found in large groups and can quickly
          infest entire plants or trees. Aphids come in a variety of colors,
          including green, black, yellow, and brown, and are typically only a
          few millimeters in size.
        </p>,
        <p>
          Aphids are a common pest in gardens, orchards, and agricultural
          fields, and they can cause significant damage to plants and trees.
          They use their piercing-sucking mouthparts to extract sap from the
          plant, which can cause leaves to wilt, turn yellow, and eventually
          die. In addition to the direct damage caused by their feeding, aphids
          can also transmit plant viruses and attract other pests, such as ants.
        </p>,
        <p>
          One of the most common signs of an aphid infestation is the presence
          of sticky, sugary residue on plant leaves and stems, known as
          honeydew. This is a byproduct of the aphids' feeding, and it can
          attract other insects, such as ants, which can exacerbate the problem.
          Honeydew can also encourage the growth of sooty mold, a black fungus
          that can further damage the plant.
        </p>,
        <p>
          There are several methods for controlling aphids on plants and trees.
          One option is to introduce natural predators, such as ladybugs or
          lacewings, which feed on aphids and can help keep their populations in
          check. Another approach is to use insecticidal soaps or oils, which
          can kill aphids on contact. However, it is important to carefully
          follow the instructions for these products to avoid damaging the
          plant.
        </p>,
        <p>
          Regular monitoring of plants and trees is also important to catch
          aphid infestations early before they become severe. Removing heavily
          infested plant parts can also help reduce the aphid population, and
          maintaining healthy plants through proper watering and fertilization
          can make them more resistant to aphids and other pests.
        </p>,
        <p>
          Aphids are a common pest that can cause significant damage to plants
          and trees. Regular monitoring and early intervention can help control
          their populations and prevent long-term damage to plants.
        </p>,
      ],
    },
    {
      image: require('../images/bagwormOpen.jpg'),
      title: 'BAGWORMS',
      alt: 'bagworm cocoon opened with visible warm',
      urlName: 'bagworms',
      credit: 'James B. Hanson, USDA Forest Service, Bugwood.org',
      creditLink:
        'https://www.forestryimages.org/browse/detail.cfm?imgnum=1394084',
      description: [
        <div>
          <div
            style={{
              float: 'right',
              flex: '1',
              width: '300px',
              marginBottom: '15px',
              maxWidth: '100%',
              position: 'relative',
            }}
            className='imageTextEmbed'
          >
            <img
              alt='bagworm hanging off an arborvitae branch'
              src={bagworms1}
              style={{ width: '100%' }}
            />

            {/* <h3
              style={{
                position: 'absolute',
                // zIndex: 2,
                bottom: '5px',
                right: '3px',
              }}
            >
              <a
                arial-label='link to source of image'
                style={{
                  color: 'rgb(218, 204, 204)',
                  textDecoration: 'none',
                  fontSize: '0.4rem',
                }}
                href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5048029'
              >
                Joseph OBrien, USDA Forest Service, Bugwood.org{' '}
              </a>
            </h3> */}
          </div>
          <p>
            Bagworms are intriguing yet destructive insects that belong to the
            family Psychidae. These caterpillar-like larvae construct unique
            protective bags made from silk and camouflaged with bits of
            vegetation, which they carry around as they feed on the leaves of
            various trees and shrubs. As they mature into moths, bagworms can
            cause significant damage to plants if left unchecked, making early
            detection and control essential for preserving garden and landscape
            health.
          </p>
        </div>,
      ],
    },
    {
      image: require('../images/crapeMyrtleBarkScale.jpg'),
      title: 'CRAPE MYRTLE BARK SCALE',
      alt: 'Crape Myrtle Bark Scale bugs on a tree trunk',
      urlName: 'crapemyrtlebarkscale',
      description: [
        <div>
          <p>
            Crape myrtle bark scale (CMBS) is a pest that has been spreading
            throughout the southeastern United States since its first detection
            in Texas in 2004. This insect is native to Asia and was first
            identified in the US in 2004 in Richardson, Texas. Since then, it
            has been reported in several states including Tennessee, Georgia,
            Alabama, Louisiana, Mississippi, and South Carolina.
          </p>
          <div
            style={{
              float: 'right',
              flex: '1',
              width: '200px',
              marginBottom: '15px',
              maxWidth: '100%',
              position: 'relative',
            }}
            className='imageTextEmbed'
          >
            <img
              alt='crapemyrtle tree with clear damage, bark is turning black from bark scale'
              src={barkScale2}
              style={{ width: '100%', height: '40vh' }}
            />

            <h3
              style={{
                position: 'absolute',
                // zIndex: 2,
                bottom: '5px',
                right: '3px',
              }}
            >
              <a
                arial-label='link to source of image'
                style={{
                  color: 'rgb(218, 204, 204)',
                  textDecoration: 'none',
                  fontSize: '0.4rem',
                }}
                href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5521505'
              >
                Jim Robbins, Univ. of Ark. CES, Bugwood.org{' '}
              </a>
            </h3>
          </div>
          <p>
            Crape myrtle bark scale (CMBS) feeds on the sap of crape myrtle
            trees, which causes a significant amount of damage to the tree. The
            insect produces a white, waxy coating on the bark that can be easily
            identified. The scales are small, about the size of a pinhead, and
            they can be found in large numbers on the bark of crape myrtle
            trees.
          </p>
          <p>
            CMBS can cause a range of issues for crape myrtle trees, including
            decreased growth, wilted or discolored leaves, and a general decline
            in tree health. If left untreated, CMBS can even lead to the death
            of the tree. The insect also produces a sticky substance called
            honeydew, which attracts other pests like ants and wasps, making the
            problem even worse.
          </p>
          <div
            style={{
              float: 'right',
              flex: '1',
              width: '200px',
              marginBottom: '15px',
              maxWidth: '100%',
              position: 'relative',
            }}
            className='imageTextEmbed'
          >
            <img
              alt='crapemyrtle bark scale close up on a branch'
              src={barkScale1}
              style={{ width: '100%', height: '40vh' }}
            />

            <h3
              style={{
                position: 'absolute',
                // zIndex: 2,
                bottom: '5px',
                right: '3px',
              }}
            >
              <a
                arial-label='link to source of image'
                style={{
                  color: 'rgb(218, 204, 204)',
                  textDecoration: 'none',
                  fontSize: '0.4rem',
                }}
                href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5521505'
              >
                Jim Robbins, Univ. of Ark. CES, Bugwood.org{' '}
              </a>
            </h3>
          </div>
          <p>
            There are several methods of controlling CMBS, including the use of
            insecticides, pruning and removing heavily infested branches, and
            even the introduction of natural predators like ladybugs and
            lacewings. Prevention is also an important measure, such as avoiding
            planting crape myrtle trees in areas where they may be exposed to
            the pest, and using proper horticultural practices like proper
            watering and fertilization.
          </p>
          <p>
            Crape myrtle bark scale is a serious pest that can cause significant
            damage to crape myrtle trees. It is important to be vigilant and
            take steps to control and prevent infestations to maintain the
            health and beauty of these trees.
          </p>
        </div>,
      ],
    },
  ];

  const selectedPest = differentPests.find((pest) => pest.urlName === pestpage);

  return (
    <Page
      title={`Dendro - ${selectedPest.title}`}
      description={`Learn More About ${selectedPest.title}`}
      pageLink={'/commonpests/:pestpage'}
    >
      <div className='commonPestsContainer'>
        <div
          className='backgroundImage'
          // background image
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>
        <div className='titleBoxPlantHealth'></div>
        {selectedPest && (
          <div>
            <h1 className='titlePlantHealth'>{selectedPest.title}</h1>
            <Card className='textPlantHealth'>
              <Card.Img
                variant='top'
                src={selectedPest.image}
                alt={selectedPest.alt}
                style={{ opacity: '0.9' }}
              />
              <h2
                style={{
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
                className='citeText'
              >
                <a
                  style={{ textDecoration: 'none', color: 'black' }}
                  href={selectedPest.creditLink}
                >
                  {selectedPest.credit}
                </a>
              </h2>
              <Card.Body>{selectedPest.description}</Card.Body>
            </Card>
          </div>
        )}
      </div>
    </Page>
  );
};

export default CommonPests;
