import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../styles/ContactForm.css';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  // const serviceId = process.env.SERVICE_ID;
  // const templateId = process.env.TEMPLATE_ID;
  // const publicKey = process.env.SERVICE_ID;

  const [buttonState, setButtonState] = useState('Send Message');

  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      from_name: '', 
      to_name: process.env.REACT_APP_ADMIN_EMAIL, 
      from_address: '', 
      phone: '', 
      reply_to: '', 
      message: '',
    },
    validationSchema: Yup.object({
      from_name: Yup.string().required('* Name is required'),
      from_address: Yup.string().required('* Address is required'),
      phone: Yup.string().required('* Phone number is required'),
      reply_to: Yup.string()
        .email('Invalid email address')
        .required('* Email field is required'),
      message: Yup.string().required(
        '* Please leave a short message with more information'
      ),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      console.log('values', values);
      try {
        emailjs
          .send(
            'service_sh69fxo',
            'template_9wqac0w',
            values,
            'zgt5-MHMtMDMAYEl2'
          )
          .then(() => {
            console.log('email sent');
            setButtonState(
              'Thank you for your message! We will get back to you as soon as possible.'
            );
            setSubmitting(false);
            resetForm();
            setDisabled(true);
          });
      } catch {
        setButtonState('Send Email');
        setSubmitting(false);
      }
    },
  });

  return (
    <div
      className='container contactFormStyle'
      style={{
        width: '60%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        border: 'none',
      }}
    >
      <form className='formFields' onSubmit={formik.handleSubmit}>
        <label htmlFor='from_name' />
        <input
          id='from_name'
          name='from_name'
          type='text'
          autoComplete='off'
          placeholder='Full Name'
          onChange={formik.handleChange}
          value={formik.values.from_name}
          className='indivFields'
        />
        <div
          className={`expandable ${
            formik.touched.from_name && formik.errors.from_name ? 'show' : ''
          }`}
        >
          {formik.errors.from_name}
        </div>
        <label htmlFor='from_address' />
        <input
          id='from_address'
          name='from_address'
          type='text'
          autoComplete='off'
          placeholder='Address'
          onChange={formik.handleChange}
          value={formik.values.from_address}
          className='indivFields'
        />
        <div
          className={`expandable ${
            formik.touched.from_address && formik.errors.from_address
              ? 'show'
              : ''
          }`}
        >
          {formik.errors.from_address}
        </div>
        <label htmlFor='phone' />
        <input
          id='phone'
          name='phone'
          type='text'
          autoComplete='off'
          placeholder='Phone Number'
          onChange={formik.handleChange}
          value={formik.values.phone}
          className='indivFields'
        />
        <div
          className={`expandable ${
            formik.touched.phone && formik.errors.phone ? 'show' : ''
          }`}
        >
          {formik.errors.phone}
        </div>
        <label htmlFor='reply_to' />
        <input
          id='reply_to'
          type='email'
          name='reply_to'
          placeholder='Email Address'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.reply_to}
          className='indivFields'
        />
        <div
          className={`expandable ${
            formik.touched.reply_to && formik.errors.reply_to ? 'show' : ''
          }`}
        >
          {formik.errors.reply_to}
        </div>
        <label htmlFor='message' />
        <textarea
          id='message'
          name='message'
          placeholder='Message'
          autoComplete='off'
          onChange={formik.handleChange}
          value={formik.values.message}
          className='indivFields'
        />
        <div
          className={`expandable ${
            formik.touched.message && formik.errors.message ? 'show' : ''
          }`}
        >
          {formik.errors.message}
        </div>
        <div
          className='col-12'
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          <button disabled={disabled} type='submit' className='sendBtn' aria-label='Send filled form button'>
            <span>{buttonState}</span>
          </button>
        </div>
        <div className='col-12 form-message'>
          <span
            id='output'
            className='output_message text-center text-uppercase'
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
