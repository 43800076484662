import React from 'react';
import Card from 'react-bootstrap/Card';
import soil1 from '../images/soil3.jpg';
import soil2 from '../images/soil4.jpg';
import '../styles/SoilCare.css';
import background from '../images/trees4.jpg';
import Page from '../components/Page';
import roots from '../images/roots.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import nutrients from '../images/plants-deficiencies-3.jpg';
const SoilCare = () => {
  return (
    <Page
      title={'Dendro - Soil Care'}
      description={
        'Trees in the urban environment need special care and treatment. Our urban soils are typically compacted and starved of the nutrients that are necessary to keep our plants thriving. '
      }
      pageLink={'/soilcare'}
    >
      <div className='SoilCareContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>
        <h2 className='titleSoil' id='soilCare'>
          SOIL MANAGEMENT AND FERTILIZATION
        </h2>
        <Card.Img
          className='mainImageSoil'
          variant='top'
          src={soil1}
          alt='Hands holding dirt'
        />

        <Card className='textSoil'>
          <Card.Body>
            <p>
              Trees in the urban environment need special care and treatment.
              Our urban soils are typically compacted and starved of the
              nutrients that are necessary to keep our plants thriving.
              Consistent monitoring and maintenance is crucial to keeping your
              trees and shrubs happy and healthy.
            </p>
            <div
              style={{
                float: 'right',
                flex: '1',
                width: '200px',
                marginBottom: '15px',
                maxWidth: '100%',
                position: 'relative',
              }}
              className='imageTextEmbed'
            >
              <img alt='' src={nutrients} style={{ width: '100%' }} />

              <h3
                style={{
                  position: 'absolute',
                  // zIndex: 2,
                  bottom: '5px',
                  right: '3px',
                }}
              >
                <a
                  arial-label='link to source of image'
                  style={{
                    color: 'rgb(218, 204, 204)',
                    textDecoration: 'none',
                    fontSize: '0.4rem',
                  }}
                  href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5048029'
                >
                  International Society of Arboriculture, Bugwood.org
                </a>
              </h3>
            </div>
            <p>Why do we need to fertilize our trees?</p>
            <p>
              We love our lush green lawns, but unfortunately, our trees do not.
              Trees in the forest rely on the dead and decaying organic matter
              on the forest floor to provide them with essential nutrients to
              keep them alive. Not only do they need the nutrients, but they
              also rely on beneficial soil organisms such as bacteria, fungi,
              protozoa and nematodes to break down those nutrients in order to
              make them available to the tree. When we build our homes, heavy
              machinery strips away the top organic layer of the soil leaving
              behind compacted dead dirt. Our soil care services can help
              revitalize you soil and keep your trees thriving for years to
              come.
            </p>
          </Card.Body>
          <Card.Body>
            <LazyLoadImage
              className='soilImages'
              variant='top'
              src={soil2}
              alt='Soil'
            />
            <Card.Title className='cardTitleSoil'>
              Annual Monitoring and Soil Management
            </Card.Title>
            <p>
              We offer Spring and Fall fertilization programs that are tailored
              specifically for your trees and shrubs. Spring and Fall
              fertilization applications are made according to the results of
              annual soil sample analyses to correct nutrient deficiencies and
              to promote the growth of new roots, as well as beneficial soil
              fungi, bacteria, nematodes and protozoa.
            </p>
          </Card.Body>
          <Card.Body>
            <LazyLoadImage
              className='soilImages'
              variant='top'
              src={roots}
              alt='Tree roots'
            />
            <Card.Title className='cardTitleSoil'>
              Root Zone Enhancement
            </Card.Title>
            <p>
              Soil compaction can have a significant impact on the health and
              growth of trees. When soil is compacted, the spaces between soil
              particles are reduced, making it difficult for tree roots to
              penetrate and absorb nutrients and water. This can lead to stunted
              growth, reduced resistance to pests and diseases, and even death
              in severe cases. Compacted soil also reduces soil oxygen levels,
              which can further exacerbate tree health problems.{' '}
            </p>
            <p>
              Root Zone Enhancement is the tilling and removal of excess soil
              around the base of the tree using compressed air. In addition,
              soil amendments such as compost, macro/micronutrients and biochar
              can be added to promote soil health and stimulate new root
              development.
            </p>
          </Card.Body>
        </Card>
      </div>
    </Page>
  );
};

export default SoilCare;
