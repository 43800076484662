import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../styles/ServicesCards.css';
import { Zoom } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ServicesCards = () => {
  const cardData = [
    {
      image: require('../images/sickLeaves.webp'),
      text: "Discover our comprehensive plant healthcare services tailored to enhance the vitality and longevity of your plants. From disease prevention to pest management, we're committed to ensuring your green spaces thrive.",
      alt: 'Picture that links to the Plant Healthcare page, showing some yellow, crusty, sick leaves',
      service: 'Plant Health',
      href: '/plantHealth',
    },
    {
      image: require('../images/prunning.jpeg'),
      text: 'Pruning trees is a crucial practice that enhances tree health and aesthetics by selectively removing dead or overgrown branches. Properly timed and executed tree pruning promotes better air circulation, sunlight exposure, and structural integrity, contributing to the long-term well-being of the tree.',
      alt: 'Pruning a japanese maple with small pruning shears',
      service: 'Pruning',
      href: '/pruning',
    },
    {
      image: require('../images/fallenTree.jpg'),
      text: 'Elevate your green space with our tree consultation services! Our certified arborists will provide personalized recommendations and expert guidance to optimize tree health, assess potential risks, and create a tailored care plan, ensuring your trees thrive for generations to come.',
      alt: 'A Fallen Tree that broke a fence at a property line',
      service: 'Consultations',
      href: '/consulting',
    },
    {
      image: require('../images/supportSystems1.jpg'),
      text: 'Protect your valuable trees and property with our top-tier tree support systems. Our engineered solutions, including cabling and bracing, will fortify your trees, preventing damage during storms and ensuring their longevity, while preserving the beauty and safety of your outdoor environment.',
      alt: 'Large tree with large broken branch with included bark',
      service: 'Tree Support Systems',
      href: '/supportSystems',
      credit: 'Joseph OBrien, USDA Forest Service, Bugwood.org',
    },
  ];

  return (
    <div
      style={{
        width: '60%',
        border: 'none',
      }}
      className='rounded-5 servicesCard'
    >
      <h2
        className='servicesTitle'
        style={{
          display: 'flex',
          paddingTop: '10px',
          paddingBottom: '10px',
          justifyContent: 'space-around',
        }}
      >
        Services
      </h2>
      <Row xs={2} s={2} md={2}>
        {cardData.map((card, i) => (
          <Col xs={12} md={6} key={card.image} className='imageCardsServ'>
            <Zoom
              triggerOnce
              // fraction={0.3}
            >
              <Card
                style={{
                  height: '100%',
                  border: 'none',
                  marginBottom: '20px',
                  backgroundColor: 'rgba(255, 255, 255, 0.0)',
                  borderRadius: '30px',
                }}
                className='cardBlock'
              >
                <Link
                  to={card.href}
                  aria-label={`link behind the image to be directed to the ${card.service} page`}
                >
                  <div
                    style={{
                      maxWidth: '100%',
                      position: 'relative',
                      // backgroundImage: 'cover'
                    }}
                    className='imageTextEmbed'
                  >
                    <LazyLoadImage
                      variant='top'
                      style={{
                        width: '100%',
                        height: '30vh',
                        objectFit: 'cover',
                      }}
                      src={card.image}
                      alt={card.alt}
                      className='imgServiceCards'
                    />
                    <h3
                      style={{
                        position: 'absolute',
                        // zIndex: 2,
                        bottom: '5px',
                        right: '3px',
                      }}
                    >
                      <a
                        arial-label='link to source of image'
                        style={{
                          color: 'rgb(218, 204, 204)',
                          textDecoration: 'none',
                          fontSize: '0.4rem',
                        }}
                        href={card.href}
                      >
                        {card.credit}
                      </a>
                    </h3>
                  </div>
                </Link>

                <Card.Body>
                  <Card.Title className='serviceCardTitle'>
                    {card.service}
                  </Card.Title>
                  <Card.Text className='serviceCardDescription'>
                    {card.text}
                  </Card.Text>
                  <Button
                    className='learnMoreButtonServices'
                    aria-label={`Learn more about ${card.service}`}
                  >
                    <Link
                      aria-label={`Learn more about ${card.service} link behind button`}
                      to={card.href}
                      style={{
                        textDecoration: 'none',
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Learn More
                    </Link>
                  </Button>
                </Card.Body>
              </Card>
            </Zoom>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ServicesCards;
