// import components
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import PhoneFloat from './components/PhoneFloat';
import Landing from './pages/Landing';
import '../src/styles/App.css';
import ContactUs from './pages/ContactUs';
import Pruning from './pages/Pruning';
import Consulting from './pages/Consulting';
import PlantHealth from './pages/PlantHealth';
import PestManagement from './pages/PestManagement';
import SoilCare from './pages/SoilCare';
import CommonPests from './pages/CommonPests';
import TreeSupport from './pages/TreeSupport';
import AboutUs from './pages/AboutUs';
import LearningResources from './pages/LearningResources';
import SpecificBlog from './pages/SpecificBlog';
import IntegratedPestManagement from './pages/IntegratedPestManagement';
import { HelmetProvider } from 'react-helmet-async';
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <div className='App' style={{ height: '100vh' }}>
      <HelmetProvider>
        {/* anything between the browserrouter tag is react router dom functionality */}
        <BrowserRouter>
          <Navigation />
          <PhoneFloat />
          {/* conditionally rendered routes */}
          <Routes>
            {/* when on localhost:3000 / route, only display the Home page */}
            {/* endpoints */}
            <Route path='/' element={<Landing />} />
            <Route path='*' element={<PageNotFound />} />

            <Route path='/contact' element={<ContactUs />} />
            <Route path='/pruning' element={<Pruning />} />
            <Route path='/consulting' element={<Consulting />} />
            <Route path='/planthealth' element={<PlantHealth />} />
            <Route path='/soilcare' element={<SoilCare />} />
            <Route path='/pestmanagement' element={<PestManagement />} />
            <Route path='/commonpests/:pestpage' element={<CommonPests />} />
            <Route
              path='/integratedPestManagement'
              element={<IntegratedPestManagement />}
            />
            <Route path='/supportsystems' element={<TreeSupport />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/learningresources' element={<LearningResources />} />
            <Route
              path='/learningresources/:blogchosen'
              element={<SpecificBlog />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
