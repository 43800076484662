import React from 'react';

const PageNotFound = () => {
  return (
    <div style={{ height: '100vh' }}>
      <h1
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: '150px',
        }}
      >
        404 Error
      </h1>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        Page Not Found
      </h2>
    </div>
  );
};

export default PageNotFound;
