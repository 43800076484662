import React from 'react';
import logo from '../images/Logo PNG.png';
import ISA from '../images/BCMA-400.png';
import TRAQ from '../images/TRAQ.jpg';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../styles/GeneralInfoCard.css';
import ImageCarousel from '../components/ImageCarousel';
import { Zoom } from 'react-awesome-reveal';

const GeneralInfoCard = () => {
  return (
    <div>
      <Zoom triggerOnce>
        <Card
          style={{
            width: '60%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            border: 'none',
          }}
          className='rounded-5 generalInfoCard'
        >
          <Row className='rowLogoCar'>
            <ImageCarousel
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-start',
                flexWrap: 'no-wrap',
                // marginTop: '50px'
              }}
            />

            <Card.Img
              variant='top'
              src={logo}
              alt='Dendro Logo'
              style={{ width: '60%', margin: 'auto' }}
              className='rounded-top-5 logoGeneralCard'
            />
          </Row>
          <Card.Body>
            <Row style={{ justifyContent: 'space-between' }}>
              <Col xs={12} s={12} lg={10} md={10}>
                <h5 style={{ marginBottom: '15px' }}>
                  <i>Dendro Tree Care</i> is a locally owned and operated
                  full-service tree care company located in Nashville,
                  Tennessee. Founded by an ISA Board Certified Master Arborist,
                  Dendro Tree Care is committed to providing the highest level
                  of safe and scientific tree care to its clients.
                </h5>
                <ul>
                  <li>Tree and Shrub Healthcare and Diagnostics</li>
                  <li>Pruning</li>
                  <li>Tree Removals</li>
                  <li>Tree Support Systems and Lightning Protection</li>
                  <li>Tree Planting</li>
                  <li>Consultation Services</li>
                </ul>
              </Col>
              <Col
                xs={12}
                s={12}
                lg={1}
                md={2}
                style={{
                  display: 'flex',
                  position: 'relative',
                }}
                className='accreditation'
              >
                <div className='mb-2'>
                  <img
                    alt='Board Certified Master Arborist logo'
                    src={ISA}
                    className='qualifImage masterImage'
                  />
                  <Card.Text
                    className='masterNum'
                    style={{ fontWeight: 'bold' }}
                  >
                    SO-10487B
                  </Card.Text>
                </div>
                <img
                  alt='Tree Risk Assessment Qualification logo'
                  src={TRAQ}
                  style={{ marginBottom: '10px' }}
                  className='qualifImage'
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Zoom>
    </div>
  );
};

export default GeneralInfoCard;
