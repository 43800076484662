import React from 'react';
import background from '../images/fallCanopy.jpg';
import '../styles/LearningResources.css';
import Card from 'react-bootstrap/Card';
import { useParams } from 'react-router-dom';
import Page from '../components/Page';
import boxwoodLeafminer from '../images/boxwoodLeafminer7.jpg';
import boxwoodSpidermites from '../images/boxwoodSpidermites.jpg';
import rootRot from '../images/rootrot4.jpg';
import volutella from '../images/volutellaBlight.png';
import boxwoodBlight from '../images/boxwoodBlight.jpg';

const SpecificBlog = () => {
  const { blogchosen } = useParams();

  const blogData = [
    {
      image: require('../images/drySoil.jpg'),
      name: 'globalwarming',
      tabName: 'Global Warming',
      title: 'THE IMPACT OF CLIMATE CHANGE ON OUR URBAN TREES',
      alt: 'image of dry soil',
      description: [
        <p>
          Climate change is having a significant impact on urban trees, and the
          consequences of these impacts are being felt in cities around the
          world. Trees play a critical role in urban ecosystems, providing a
          range of benefits, including improving air quality, reducing urban
          heat islands, and enhancing the aesthetic appeal of urban areas.
          However, as temperatures rise, rainfall patterns change, and extreme
          weather events become more frequent, urban trees are facing a range of
          challenges that threaten their long-term health and viability.
        </p>,
        <p>
          One of the most significant impacts of climate change on urban trees
          is heat stress. As temperatures rise, trees are increasingly exposed
          to high temperatures, which can damage leaves and limit growth.
          Prolonged exposure to heat stress can also lead to tree mortality,
          particularly for species that are not well adapted to hot and dry
          conditions. This is a particular concern in cities, where urban heat
          islands can exacerbate the effects of heat stress on trees and other
          vegetation.
        </p>,
        <p>
          Another challenge for urban trees is drought. Climate change is
          causing more frequent and severe droughts, which can limit the
          availability of water for trees. Trees that are not adapted to drought
          conditions may experience water stress, which can lead to leaf loss,
          stunted growth, and eventual death. This is a particular concern in
          urban areas, where trees may be planted in areas with limited access
          to water, such as paved or compacted soils.
        </p>,
        <p>
          In addition to heat stress and drought, climate change is also
          increasing the incidence of pests and diseases that can attack urban
          trees. Warmer temperatures and longer growing seasons can create
          favorable conditions for pests and diseases, such as the emerald ash
          borer, which is responsible for killing millions of ash trees in North
          America. Other pests and diseases, such as bark beetles and fungal
          infections, are also becoming more common in urban areas as a result
          of climate change.
        </p>,
        <p>
          Changes in rainfall patterns are also affecting urban trees. Climate
          change is altering the timing and intensity of rainfall, which can
          affect the growth and health of trees. Trees that are adapted to
          wetter conditions may suffer if rainfall becomes less frequent, while
          trees that are adapted to drier conditions may struggle if rainfall
          becomes more frequent. This can lead to soil degradation, including
          soil erosion and compaction, which can damage tree roots and limit
          their ability to absorb water and nutrients.
        </p>,
        <p>
          To mitigate the impacts of climate change on urban trees, city
          planners and urban foresters must take steps to ensure the long-term
          health and viability of urban forests. This includes planting a
          diverse range of tree species that are well adapted to local climate
          conditions, providing adequate water and nutrients for trees, and
          managing pests and diseases in a sustainable and integrated manner. It
          also means taking steps to reduce the urban heat island effect, such
          as planting trees in strategic locations to provide shade and reduce
          the amount of sunlight that reaches the ground.
        </p>,
        <p>
          Climate change is posing significant challenges for urban trees, but
          with careful planning and management, it is possible to ensure the
          long-term health and viability of urban forests. By working together,
          city planners, urban foresters, and communities can help to create
          vibrant, resilient, and sustainable urban ecosystems that provide a
          range of benefits for people and the environment.
        </p>,
      ],
    },
    // {
    //   name: 'boxwoodcareguide',
    //   image: require('../images/boxwoodBlog.jpg'),
    //   title: 'BOXWOOD CARE GUIDE',
    //   alt: 'Boxwood plant',
    //   href: 'https://www.forestryimages.org/browse/detail.cfm?imgnum=1581749',
    //   cite: 'John Ruter, University of Georgia, Bugwood.org',
    //   tabName: 'Boxwood Care Guide',
    //   description: [
    //     <h2 className='titleArticle'>Common Pests of Boxwoods</h2>,
    //     <hr></hr>,
    //     <div>
    //       <div className='columnsArticle'>
    //         <h3>Boxwood Leafminer:</h3>

    //         <p>
    //           Boxwood leafminer is a common pest that can cause significant
    //           damage to boxwood shrubs. The insect's larvae feed on the leaves,
    //           causing them to turn brown and drop prematurely. The adult
    //           leafminer is a small, black fly that lays eggs on the underside of
    //           boxwood leaves in the spring. The larvae then tunnel through the
    //           leaves, creating tunnels or galleries that can be seen when the
    //           leaf is held up to the light. The best way to prevent boxwood
    //           leafminer infestations is to keep boxwood plants healthy through
    //           proper watering, fertilization, and pruning. If leafminer damage
    //           is detected, it is best to prune affected branches and destroy
    //           them to prevent further infestation. Insecticides can also be used
    //           to control boxwood leafminer, but they should be applied at the
    //           appropriate time to target the larvae before they cause
    //           significant damage.
    //         </p>
    //         <div
    //           style={{
    //             maxWidth: '100%',
    //             position: 'relative',
    //             // backgroundImage: 'cover'
    //             marginBottom: '20px',
    //           }}
    //           className='imageTextEmbed'
    //         >
    //           <img
    //             variant='top'
    //             className='imageSpecificBlog'
    //             alt=''
    //             src={boxwoodLeafminer}
    //             style={{ width: '100%' }}
    //           />

    //           <h3
    //             style={{
    //               position: 'absolute',
    //               // zIndex: 2,
    //               bottom: '5px',
    //               right: '3px',
    //             }}
    //           >
    //             <a
    //               arial-label='link to source of image'
    //               style={{
    //                 color: 'rgb(218, 204, 204)',
    //                 textDecoration: 'none',
    //                 fontSize: '0.4rem',
    //               }}
    //               href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5369681'
    //             >
    //               Brian Kunkel, University of Delaware, Bugwood.org
    //             </a>
    //           </h3>
    //         </div>
    //         <h4>Recommended treatment:</h4>

    //         <p>
    //           Apply Dinotefuran (Safari) as a soil drench in early spring.
    //           Imidacloprid will also help control leafminer populations, however
    //           this product is known to attract spider mites.
    //         </p>
    //       </div>
    //     </div>,
    //     <div>
    //       <div className='columnsArticle'>
    //         <h3>Boxwood Spider Mites:</h3>

    //         <p>
    //           Boxwood spider mites are a common pest that can cause damage to
    //           boxwood shrubs. These tiny pests are difficult to see with the
    //           naked eye and can go unnoticed until significant damage has been
    //           done. Spider mites feed on the plant's sap, causing leaves to
    //           become stippled or speckled and eventually turn yellow or brown.
    //           In severe infestations, the leaves may drop prematurely, leading
    //           to defoliation. The best way to prevent spider mite infestations
    //           is to keep boxwood plants healthy through proper watering,
    //           fertilization, and pruning. Regularly washing the foliage with a
    //           strong stream of water can also help to dislodge and remove spider
    //           mites. If an infestation is detected, insecticidal soap or
    //           horticultural oil can be applied to the foliage to control spider
    //           mites. However, it is important to follow the instructions
    //           carefully and apply the treatment at the appropriate time to be
    //           effective.
    //         </p>
    //         <div
    //           style={{
    //             maxWidth: '100%',
    //             position: 'relative',
    //             // backgroundImage: 'cover'
    //             marginBottom: '20px',
    //           }}
    //           className='imageTextEmbed'
    //         >
    //           <img
    //             variant='top'
    //             className='imageSpecificBlog'
    //             alt=''
    //             src={boxwoodSpidermites}
    //             style={{ width: '100%', objectFit: 'cover' }}
    //           />

    //           <h3
    //             style={{
    //               position: 'absolute',
    //               // zIndex: 2,
    //               bottom: '5px',
    //               right: '3px',
    //             }}
    //           >
    //             <a
    //               arial-label='link to source of image'
    //               style={{
    //                 color: 'rgb(218, 204, 204)',
    //                 textDecoration: 'none',
    //                 fontSize: '0.4rem',
    //               }}
    //               href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5427666'
    //             >
    //               Eric R. Day, Virginia Polytechnic Institute and State
    //               University, Bugwood.org{' '}
    //             </a>
    //           </h3>
    //         </div>
    //         <h4>Recommended treatment:</h4>

    //         <p>
    //           Apply Horticultural oil in late fall and early spring while
    //           temperatures are below 80F to suppress overwintering mites. If
    //           high populations are still present, apply a miticide such as
    //           Lucid, Forbid or Bifenamite 1-3 times during the growing season.
    //           Always be sure to rotate your miticides
    //         </p>
    //       </div>
    //     </div>,
    //     <div>
    //       <div className='columnsArticle'>
    //         <h3>Phytophthora Root Rot:</h3>

    //         <p>
    //           Phytophthora root rot is a serious fungal disease that can affect
    //           boxwood shrubs. It is caused by the pathogen Phytophthora
    //           cinnamomi, which attacks the roots of the plant and can eventually
    //           lead to plant death. Symptoms of phytophthora root rot in 2
    //           boxwood include yellowing leaves, stunted growth, and dieback of
    //           branches. In severe cases, the foliage may turn brown and the
    //           plant may wilt. The disease thrives in wet soil conditions, so
    //           prevention is key to avoiding phytophthora root rot in boxwood. To
    //           prevent the disease, it is important to plant boxwood in
    //           well-drained soil and avoid overwatering. Fungicides can be used
    //           to treat infected plants, but they are most effective when applied
    //           early in the disease cycle. Once a plant is severely infected, it
    //           may be necessary to remove it and replace it. In addition to
    //           fungicide treatments, adding a quality compost will help break
    //           down the phytophthora organisms and keep the populations at bay
    //           long term. It is typically recommended to do fungicide
    //           applications for at least one year once the disease is discovered.
    //         </p>
    //         <div
    //           style={{
    //             maxWidth: '100%',
    //             position: 'relative',
    //             // backgroundImage: 'cover'
    //             marginBottom: '20px',
    //           }}
    //           className='imageTextEmbed'
    //         >
    //           <img
    //             variant='top'
    //             className='imageSpecificBlog'
    //             alt=''
    //             src={rootRot}
    //             style={{ width: '100%', objectFit: 'cover' }}
    //           />

    //           <h3
    //             style={{
    //               position: 'absolute',
    //               // zIndex: 2,
    //               bottom: '5px',
    //               right: '3px',
    //             }}
    //           >
    //             <a
    //               arial-label='link to source of image'
    //               style={{
    //                 color: 'rgb(218, 204, 204)',
    //                 textDecoration: 'none',
    //                 fontSize: '0.4rem',
    //               }}
    //               href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5625675'
    //             >
    //               Craig Grau, Bugwood.org
    //             </a>
    //           </h3>
    //         </div>
    //         <h4>Recommended treatment:</h4>

    //         <p>
    //           Apply a Mefanoxam soil drench in early spring, followed by a
    //           Reliant soil drench in late summer/ early fall.
    //         </p>
    //       </div>
    //     </div>,
    //     <div>
    //       <div className='columnsArticle'>
    //         <h3>Volutella Blight:</h3>

    //         <p>
    //           Volutella blight is a fungal disease that affects boxwood shrubs.
    //           It is caused by the fungus Volutella buxi and can cause
    //           significant damage to the plant if left untreated. Symptoms of
    //           volutella blight include brown or tan spots on the leaves and
    //           stems, as well as black or pink spores on infected tissues. The
    //           disease is most prevalent in warm, humid weather and can be spread
    //           by water or wind. To prevent volutella blight in boxwood, it is
    //           important to avoid overhead watering and to prune the plant
    //           regularly to improve air circulation. Fungicides can also be used
    //           to control the disease, but they are most effective when applied
    //           preventively. Once a plant is severely infected, it may be
    //           necessary to remove and replace the infected plant.
    //         </p>
    //         <div
    //           style={{
    //             maxWidth: '100%',
    //             position: 'relative',
    //             marginBottom: '20px',
    //           }}
    //           className='imageTextEmbed'
    //         >
    //           <img
    //             variant='top'
    //             className='imageSpecificBlog'
    //             alt=''
    //             src={volutella}
    //             style={{ width: '100%', objectFit: 'cover' }}
    //           />

    //           <h3
    //             style={{
    //               position: 'absolute',
    //               // zIndex: 2,
    //               bottom: '5px',
    //               right: '3px',
    //             }}
    //           >
    //             <a
    //               arial-label='link to source of image'
    //               style={{
    //                 color: 'rgb(218, 204, 204)',
    //                 textDecoration: 'none',
    //                 fontSize: '0.4rem',
    //               }}
    //               href='https://plantpathology.ca.uky.edu/files/ppfs-or-w-26.pdf'
    //             >
    //               University of Kentucky, College of Agriculture, Food &
    //               Environment, Extension Plant Pathology
    //             </a>
    //           </h3>
    //         </div>
    //         <h4>Recommended treatment:</h4>

    //         <p>
    //           Apply a foliar fungicide treatment in early to late spring when
    //           conditions are favorable for Volutella infections.{' '}
    //         </p>
    //       </div>
    //     </div>,
    //     <div>
    //       <div className='columnsArticle'>
    //         <h3>Boxwood Blight:</h3>

    //         <p>
    //           Boxwood blight is a serious fungal disease that affects boxwood
    //           shrubs. It is caused by the pathogen Calonectria pseudonaviculata
    //           and can cause rapid defoliation and death of the plant. Symptoms
    //           of boxwood blight include brown spots on leaves that can enlarge
    //           and cause the leaves to drop prematurely, as well as black streaks
    //           on stems. The disease can be spread through water, wind, or
    //           contaminated tools and equipment. To prevent boxwood blight, it is
    //           important to avoid overhead watering and to prune the plant
    //           regularly to improve air circulation. It is also important to
    //           clean and sanitize tools and equipment before using them on
    //           boxwood plants. If an infestation is detected, it is important to
    //           remove infected plant material and dispose of it properly to
    //           prevent further spread of the disease. Fungicides can also be used
    //           to control the disease, but they are most effective when applied
    //           preventively.
    //         </p>
    //         <div
    //           style={{
    //             maxWidth: '100%',
    //             position: 'relative',
    //             marginBottom: '20px',
    //           }}
    //           className='imageTextEmbed'
    //         >
    //           <img
    //             variant='top'
    //             className='imageSpecificBlog'
    //             alt=''
    //             src={boxwoodBlight}
    //             style={{ width: '100%', objectFit: 'cover' }}
    //           />

    //           <h3
    //             style={{
    //               position: 'absolute',
    //               // zIndex: 2,
    //               bottom: '5px',
    //               right: '3px',
    //             }}
    //           >
    //             <a
    //               arial-label='link to source of image'
    //               style={{
    //                 color: 'rgb(218, 204, 204)',
    //                 textDecoration: 'none',
    //                 fontSize: '0.4rem',
    //               }}
    //               href='https://www.forestryimages.org/browse/detail.cfm?imgnum=5513137'
    //             >
    //               David L. Clement, University of Maryland, Bugwood.org
    //             </a>
    //           </h3>
    //         </div>
    //         <h4>Recommended treatment:</h4>

    //         <p>
    //           Apply a foliar fungicide treatment 1-3 4 times in early summer to
    //           mid-fall to prevent infections. This treatment is not recommended
    //           unless Boxwood Blight cases have been confirmed in your area.
    //         </p>
    //       </div>
    //     </div>,
    //     <div>
    //       <div className='columnsArticle'>
    //         <h2 className='titleArticle'>FERTILIZATION</h2>

    //         <p>
    //           Fertilizing boxwoods is an essential part of their care to
    //           maintain their health and beauty. Boxwoods are heavy feeders and
    //           require regular applications of fertilizer to promote healthy
    //           growth and maintain their green color. The best time to fertilize
    //           boxwoods is in the early spring before new growth appears, and
    //           again in the fall. Slow-release fertilizers are the best option
    //           for boxwoods because they provide a steady supply of nutrients
    //           over an extended period. It is important to follow the
    //           instructions on the fertilizer package carefully and to apply the
    //           correct amount for the size of the plant. Over-fertilizing can
    //           cause excessive growth and make the plant more susceptible to
    //           pests and diseases. Additionally, it is important to water the
    //           plant thoroughly after applying fertilizer to prevent burn and to
    //           ensure that the nutrients are taken up by the roots. For best
    //           results, soil samples should be taken annually to identify and
    //           correct any nutrient deficiencies in the soil. As a general
    //           Boxwood fertilizer for our area, a granular 8-0-6 fertilizer can
    //           be applied at a rate of 30lbs per 1,000 sqft in early Spring and
    //           Fall. To reduce plant stress during the hot and dry summer months,
    //           a potassium phosphite soil drench can be applied directly to the
    //           root zone.
    //         </p>

    //         <h3>Additional Notes:</h3>

    //         <p>
    //           Most diseases and insects associated with Boxwoods, as well as
    //           other plants in your landscape are secondary issues as a result of
    //           high plant stress. Proper cultural practices such as pruning,
    //           watering and plant placement play a crucial role in keeping your
    //           plants happy and pest free.
    //         </p>
    //       </div>
    //     </div>,
    //   ],
    // },
  ];

  const selectedBlog = blogData.find((blog) => blog.name === blogchosen);

  return (
    <Page
      title={`Dendro - ${selectedBlog.tabName}`}
      description={`Learn About ${selectedBlog.title.toLowerCase()}`}
      pageLink={'/learningresources/:blogchosen'}
    >
      <div className='BlogContainer'>
        <div
          className='backgroundImage'
          style={{
            backgroundImage: `url(${background})`,
            opacity: '0.4',
          }}
        ></div>

        {selectedBlog && (
          <div>
            <h1 className='titleBlog'>{selectedBlog.title}</h1>
            <Card
              key={selectedBlog.name}
              className='textBlog'
              style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}
            >
              <div
                style={{
                  maxWidth: '100%',
                  position: 'relative',
                  // backgroundImage: 'cover'
                }}
                className='imageTextEmbed'
              >
                <Card.Img
                  variant='top'
                  className='imageSpecificBlog'
                  alt={selectedBlog.alt}
                  src={selectedBlog.image}
                  style={{ width: '100%', objectFit: 'cover' }}
                />

                <h3
                  style={{
                    position: 'absolute',
                    // zIndex: 2,
                    bottom: '5px',
                    right: '3px',
                  }}
                >
                  <a
                    arial-label='link to source of image'
                    style={{
                      color: 'rgb(218, 204, 204)',
                      textDecoration: 'none',
                      fontSize: '0.4rem',
                    }}
                    href={selectedBlog.href}
                  >
                    {selectedBlog.cite}
                  </a>
                </h3>
              </div>

              {/* <Card.Img
                variant='top'
                src={selectedBlog.image}
                alt={selectedBlog.alt}
                className='imageSpecificBlog'
              /> */}
              <Card.Body>{selectedBlog.description}</Card.Body>
            </Card>
          </div>
        )}
      </div>
    </Page>
  );
};

export default SpecificBlog;
